import Ee from "../assets/ee.png"
import TheFragrance from "../assets/the-fragrance-shop-bg-tr.png"
import Wild from "../assets/wild.png"
import SecretSales from "../assets/secret-sales.png"
import clearpay from "../assets/clearpay.png"
import plusnet from "../assets/plusnet.png"
import BalanceCoffe from "../assets/balance-coffee.png"
import InnerMost from "../assets/inner-most.jpg"
import Jimmys from "../assets/jimmys.png"
import Kocktail from "../assets/kocktail.png"
import Boohoo from "../assets/Boohoo-Logo.png"
import superdrug from "../assets/superdrug.png"
import visionexpress from "../assets/visionexpress.png"
import OCDO from "../assets/OCDO.L_BIG-86aa885b.png"


const homepageTestimonials = [
  {
    id: "ee",
    alt: "",
    img: Ee,
  },
  {
    id: "wild",
    alt: "",
    img: Wild,
  },
  {
    id: "secretsales",
    alt: "",
    img: SecretSales,
  },
  {
    id: "clearpay",
    alt: "",
    img: clearpay,
  },
  {
    id: "boohoo",
    alt: "",
    img: Boohoo,
  },
  {
    id: "plusnet",
    alt: "",
    img: plusnet,
  },
  {
    id: "superdrug",
    alt: "",
    img: superdrug,
  },
  {
    id: "visionexpress",
    alt: "",
    img: visionexpress,
  },
  {
    id: "the-fragrance-shop-bg-tr",
    alt: "",
    img: OCDO,
  },
  {
    id: "the-fragrance",
    alt: "",
    img: TheFragrance,
  }
]

const gopageTestimonials = [
  {
    
    id: "kocktail",
    alt: "",
    img: Kocktail,
  },
  {
    id: "balance-coffee",
    alt: "",
    img: BalanceCoffe,
  },
  {
    id: "inner-most",
    alt: "",
    img: InnerMost,
  },
  {
    id: "jimmys",
    alt: "",
    img: Jimmys,
  },
]

export {
  homepageTestimonials,
  gopageTestimonials
}
